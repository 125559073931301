import {useGetPaymentsV1CMQuery} from "../../core/api/clockmaker-analytical-data-api";
import Loading from "../../components/Common/Loading";
import {DataGrid} from "@mui/x-data-grid";
import {useGetBattlesV1TOHQuery, useGetPaymentsV1TOHQuery} from "../../core/api/toh-analytical-data-api";

const columns = [
    {
        label: 'timestamp',
        field: 'timestampClient',
        flex: 1
    },

    {
        label: 'Gem balance',
        field: 'gemBalance',
        width: 100
    },
    {
        label: 'Energy balance',
        field: 'energyBalance',
        width: 100
    },
    {
        label: 'City id',
        field: 'cityId',
        width: 100
    },
    {
        label: 'Fight name',
        field: 'fightName',
        flex: 1
    },
    {
        label: 'Type',
        field: 'type',
        width: 100
    },

    {
        label: 'Attempt',
        field: 'attempt',
        width: 100
    },
    {
        label: 'Result',
        field: 'result',
        width: 100
    },

]

const BattlesTOH = (props) => {

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        isFetching,
        error
    } = useGetBattlesV1TOHQuery(props.deviceId)

    let payments = ""

    if (typeof data === "object") {

        payments = <DataGrid
            getRowId={(row) => row.timestamp}
            rows={data}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            autoHeight

        />
    } else {
        payments = "no data"
    }

    return (
        <Loading
            isLoading={isLoading||isFetching}
            isError={isError}
            error={error}
            isSuccess={isSuccess}
        >
            {payments}

        </Loading>
    )
}

export default BattlesTOH