import {Box, Card, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import SimpleTable from "../../../components/Common/Elements";
import ListJson from "../ListJson";
import TableJson from "../TableJson";

export default function CitiesTable({cities}) {


    return (
        <Box>

            <Grid container spacing={2}>
                <Grid sm={3} item>
                    <Typography sx={{pl:2, pt:2}} fontSize={24}>Current</Typography>
                    <TableJson item={cities} fields={["CityId","Stated","Completed"]} />
                </Grid>
            </Grid>
            {Object.keys(cities['Cities']).map(key => (
                <>
                    <Typography sx={{pl:2, pt:2}} fontSize={24}>{key}</Typography>

                    <Grid container spacing={2}>
                        <Grid sm={4} item>
                            Info
                            <TableJson item={cities['Cities'][key]} fields={["Completed","LatestQuest"]} />
                        </Grid>
                        <Grid sm={4} item>
                            Battle
                            <TableJson item={cities['Cities'][key]['Battle']['PreferredHeroRoster']} fields={Object.keys(cities['Cities'][key]['Battle']['PreferredHeroRoster'])} />

                        </Grid>
                    </Grid>
                </>
            ))}



        </Box>
    );
}