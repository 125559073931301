import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import SimpleTable from "../../components/Common/Elements";

import {Card, CardContent, Grid, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";


import Loading from "../../components/Common/Loading";
import {useGetStateV2CMQuery} from "../../core/api/cloclmaker-state-api";
import {
    countDisenchantedBuildings,
    formatDate, getDeviceIdAbsentWarning,
    getItemByName,
    getLastSession,
    HOUSES,
    paymentUserStatus,
    timestampToDate
} from "../../core/utils/constants";
import TechInfoCM from "../TechInfo/techInfoCM";

import { parse, stringify } from 'lossless-json'
import {useSelector} from "react-redux";
import UserSocialInfo from "../UserInfo/userSocialInfo";
import UserSocialInfoCM from "../UserInfo/userSocialInfoCM";
import {format} from "date-fns";
import {useGetStateV2TOHQuery} from "../../core/api/toh-state-api";
import TableJson from "../UserStates/TableJson";
import {Alert} from "@mui/lab";


function DashboardTOH() {

    const CommonData = useSelector((state) => state.data.CommonData)
    const deviceId = CommonData.deviceId
    const stateId = CommonData.stateId
    const deviceIdIsEmpty = deviceId == null || deviceId == '' || typeof deviceId == 'object';

    const {
        data: obj,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error
    } = useGetStateV2TOHQuery({deviceId: deviceId, stateId: stateId})



    let panel1 = ""
    //
    // useEffect(() => {
    //     console.log(deviceIdIsEmpty)
    //     debugger
    // }, [CommonData])
    //
    // useEffect(() => {
    //     debugger
    // }, [])
    //
    // debugger

    debugger

    if (typeof obj === "object" && obj.hasOwnProperty('data')) {
        try {
            const data = JSON.parse(obj.data)

            if(data.hasOwnProperty('User')) {

                let source = {
                    account_info: [],
                    progress_info: []
                }


                // let lastSession = ''
                // if (data.hasOwnProperty('LastSessionsState')) {
                //     lastSession = getLastSession(data.LastSessionsState._lastSessions)
                //     lastSession = formatDate(lastSession)
                // }
                // if (data.hasOwnProperty('SessionsState')) {
                //     lastSession = getLastSession(data.SessionsState._lastSessions)
                //     lastSession = formatDate(lastSession)
                // }

                source = {
                    cities: data['Cities']['Cities'],
                    account_info: [
                        ["User", data?.User],
                        ["ClientVer", data?.ClientVer._major+'.'+data?.ClientVer._minor +'.' +data?.ClientVer._build],
                        ["InitialClientVer", data.InitialClientVer._major+'.'+data.InitialClientVer._minor +'.' +data.InitialClientVer._build],
                        ["Reg", data.hasOwnProperty('Reg')?format(data?.Reg*1000, 'dd.MM.yyyy HH:mm:ss'):0],
                        ["SessionTimestamp", data.hasOwnProperty('SessionTimestamp')?format(data?.SessionTimestamp*1000, 'dd.MM.yyyy HH:mm:ss'):0],
                        ["PrevSessionTimestamp", data.hasOwnProperty('PrevSessionTimestamp')?format(data?.PrevSessionTimestamp*1000, 'dd.MM.yyyy HH:mm:ss'):0],
                        ["SaveId", data?.SaveId],
                    ],
                    setting: data.hasOwnProperty('Settings')?Object.keys(data.Settings).map((key) => ([key, data.Settings[key].toString()])):[]

                }


                panel1 = <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <Card>
                            <CardContent>
                                <Typography sx={{fontSize: 16}}>
                                    Account info
                                </Typography>
                            </CardContent>

                            <SimpleTable data={source.account_info}/>
                        </Card>

                    </Grid>
                    <Grid item sm={4}>
                        <Card>
                            <CardContent>
                                <Typography sx={{fontSize: 16}}>
                                    Current City
                                </Typography>
                            </CardContent>

                            {source.cities&&<TableJson item={source.cities} fields={["CityId","Stated","Completed"]} />}
                        </Card>

                    </Grid>
                    <Grid item sm={4}>
                        <Card>
                            <CardContent>
                                <Typography sx={{fontSize: 16}}>
                                    Settings
                                </Typography>
                            </CardContent>

                            {source.setting&&<SimpleTable data={source.setting}/>}
                        </Card>

                    </Grid>

                </Grid>


            } else if (typeof obj === "object" && obj.data.hasOwnProperty('error')) {
                panel1 = obj.data.error
            }

        } catch (e) {
            panel1 = <Alert severity={"warning"}>Unable to parse, not JSON</Alert>
        }


    } else {
        panel1 = 'unknown error'
    }

    return (
        <>
            {deviceIdIsEmpty ? getDeviceIdAbsentWarning() :
                <Loading
                    isLoading={isLoading||isFetching}
                    isError={isError}
                    error={error}
                >
                    <div>{panel1}</div>
                </Loading>}

        </>
    );

}

export default DashboardTOH
